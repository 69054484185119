let Datas = [
  {
    id: 1,
    title: "Background",
    routeAddress: [
      {
        id: 2,
        route: "/backgroundcolor",
        name: "Background Color",
      },
      {
        id: 3,
        route: "/backgroundgradient",
        name: "Background Gradient",
      },
    ],
  },
  {
    id: 4,
    title: "Box",
    routeAddress: [
      {
        id: 5,
        route: "/border",
        name: "Border",
      },
      {
        id: 6,
        route: "/BorderRadius",
        name: "Border Radius",
      },
      {
        id: 7,
        route: "/box/boxshadow",
        name: "Box-Shadow",
      },
      {
        id: 8,
        route: "/box/opasity",
        name: "Opasity",
      },
    ],
  },
  {
    id: 9,
    title: "Filter",
    routeAddress: [
      {
        id: 10,
        route: "/filter/blur",
        name: "Blur",
      },
      {
        id: 11,
        route: "/filter/sepia",
        name: "Sepia",
      },
      {
        id: 12,
        route: "/filter/brightness",
        name: "Brightness",
      },
      {
        id: 13,
        route: "/filter/contrast",
        name: "Contrast",
      },
      {
        id: 14,
        route: "/filter/grayscale",
        name: "Grayscale",
      },
      {
        id: 15,
        route: "/filter/heurotate",
        name: "Heu-Rotate",
      },
      {
        id: 16,
        route: "/filter/invert",
        name: "Invert",
      },
      {
        id: 17,
        route: "/filter/saturate",
        name: "Saturate",
      },
      {
        id: 18,
        route: "/filter/dropshadow",
        name: "Drop-Shadow",
      },
    ],
  },
  {
    id: 19,
    title: "Text",
    routeAddress: [
      {
        id: 20,
        route: "/text/fontsize",
        name: "Font-size",
      },
      {
        id: 21,
        route: "/text/color",
        name: "Text-color",
      },
      {
        id: 22,
        route: "/text/decoration",
        name: "Text-decoration",
      },
      {
        id: 23,
        route: "/text/transform",
        name: "Text-transform",
      },
      {
        id: 24,
        route: "/text/letterSpacing",
        name: "Letter-Spacing",
      },
      {
        id: 25,
        route: "/text/lineheight",
        name: "Line-height",
      },
      {
        id: 26,
        route: "/text/textalign",
        name: "Text-align",
      },
      {
        id: 27,
        route: "/text/fontweight",
        name: "Font-weight",
      },
    ],
  },
  {
    id: 28,
    title: "Transform",
    routeAddress: [
      {
        id: 29,
        route: "/transform/translateX",
        name: "TranslateX",
      },
      {
        id: 30,
        route: "/transform/translateY",
        name: "TranslateY",
      },
      {
        id: 31,
        route: "/transform/scaleX",
        name: "ScaleX",
      },
      {
        id: 32,
        route: "/transform/scaleY",
        name: "ScaleY",
      },
      {
        id: 33,
        route: "/transform/skewX",
        name: "SkewX",
      },
      {
        id: 34,
        route: "/transform/skewY",
        name: "SkewY",
      },
      {
        id: 35,
        route: "/transform/rotate",
        name: "Rotate",
      },
    ],
  },
  {
    id: 36,
    title: "FlexBox",
    routeAddress: [
      {
        id: 37,
        route: "/flexbox",
        name: "FlexBox",
      },
    ],
  },
];

export default Datas;
