import React from 'react'
import './Author.css'

export default function Author() {
  return (
    <div>
        <p className='author'>By Mohammad Yousefvand</p>
    </div>
  )
}
